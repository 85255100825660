@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --GRAD_01: #c85ef7;
} */

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-Black.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-BookItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-BookItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-Book.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-Book.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-Bold.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-ExtraBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-RegularItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-Light.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-UltraBlackItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-UltraBlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-Regular.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-Thin.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-UltraBlack.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-UltraBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-UltraLightItalic.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-UltraLightItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SonyGothic";
  src: url("./assets/fonts/SonnyGothic-UltraLight.woff2") format("woff2"),
    url("./assets/fonts/SonnyGothic-UltraLight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  /* font-family: "Sonny_Gothic", sans-serif; / */
  font-family: "SonyGothic", sans-serif;
  background-color: black;
  overflow-x: hidden;
}

.plain_input > input:focus,
textarea:focus {
  outline: none;
}

.line {
  width: 100%;
  height: 2px;
  border: none;
  margin: 0;
  padding: 0;
}

.above {
  margin-bottom: 5px;
  background-color: #373639;
  /* Set line color */
}

.below {
  margin-top: 5px;
  background-color: #373639;
  /* Set line color */
}

.texttualgradient {
  background: -webkit-linear-gradient(bottom, #775cf4, #c85ef7);
  background: -o-linear-gradient(top, #775cf4, #c85ef7);
  background: -moz-linear-gradient(top, #775cf4, #c85ef7);
  background: linear-gradient(to top, #775cf4, #c85ef7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.imgGrads {
  max-width: 1000%;
}

progress::-webkit-progress-bar {
  background-color: #fff;
}

progress::-webkit-progress-value {
  background-color: #cdb8fb !important;
}

/* For Firefox */
progress {
  background-color: #fff;
}

progress::-moz-progress-bar {
  background-color: #cdb8fb !important;
}

/* For IE10 */
progress {
  background-color: #fff;
}

progress {
  background-color: #cdb8fb;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 40%;
  height: 40%;
  object-fit: cover;
}

.gradient-hover-effect {
  display: flex;
  font-family: inherit;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.6s;
  place-content: center;
}

.gradient-hover-effect:hover {
  background-color: #775cf4;
  color: #fff;
}

p {
  -webkit-font-smoothing: antialiased;
}
.custom_slide {
  transition: all 0.4s linear;
}
.custom_text_slide {
  transition: all 0.4s linear;
}

/* hero form */

.token-wrapper.middle {
  @apply hidden lg2:flex;
}

.token-wrapper {
  @apply flex gap-3 lg2:gap-[0.3rem] xl:gap-3 items-center leading-[150%] justify-center bg-[#fff]/[0.07] w-full py-2 md:py-3.5 rounded-[30px];
}

.form-input {
  @apply text-white text-lg font-normal antialiased leading-[150%] py-3 focus:ring-2 focus:outline-none transition-all duration-75 focus:ring-purple-light flex items-center px-4 bg-[#fff]/[0.07]  w-full rounded-[30px] placeholder-purple-light;
}

button.with-icon {
  @apply flex justify-center gap-3 items-center;
}

select:focus {
  background-color: #202020;
}
